.editor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    flex-direction: row;
    height: 100%;
    padding-bottom: 40px;
    padding-top: 40px;


    .kiosk {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;
        flex-direction: row;
        height: 100%;
        position: relative;
        transition: 0.5s;



        .left {
            width: 50%;
            margin: auto;
        }

        .right {
            width: 50%;

            .wrap {
                flex-wrap: wrap;
            }

            .color-select {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .colorPicker {
                    flex-shrink: 0;
                    padding: 15px;
                    color: var(--main-text-color);
                    border-radius: 10px;
                    font-weight: 500;
                    border: 2px solid;
                    border-color: var(--border-color);
                    cursor: pointer;

                    .popover {

                        position: 'absolute';
                        z-index: 10;

                        .cover {
                            position: fixed;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                        }
                    }
                }
            }
        }

        .kiosk-container {
            background-color: #1F1F1F;
            border-radius: 30px;

            width: 75%;
            aspect-ratio: 9/16;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;

            .branding {
                height: 5%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    height: 70%;

                    .icon {
                        fill: white;
                    }
                }
            }

            .kiosk-panel {
                background-color: #000000;
                width: 95%;
                border-radius: 15px;
                aspect-ratio: 9/16;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;



                .screen {
                    width: 95%;
                    border-radius: 10px;
                    aspect-ratio: 9/16;
                    margin: auto;

                    .header {

                        width: 100%;
                        height: 10%;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h2 {
                            margin: auto;
                            padding: 0;
                        }

                    }

                    .content {
                        background-color: transparent;
                        width: 100%;
                        height: 90%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .cart-line {
                            width: 100%;
                            height: 10%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            padding-left: 2%;
                            padding-right: 2%;

                            .cart-sum {
                                height: 50%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;

                                .price {
                                    font-size: calc(3px + 0.390625vw);

                                }

                                p {
                                    padding: 0;
                                    margin: auto;
                                    font-size: calc(6px + 0.390625vw);

                                }
                            }

                            .pay {
                                height: 50%;
                                padding: 0.4rem;
                                border-radius: 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: calc(6px + 0.390625vw);
                                font-weight: bold;

                            }

                            .rendelesem {
                                height: 50%;
                                padding: 0.4rem;
                                border: 1px solid;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: calc(6px + 0.390625vw);
                                border-radius: 3px;
                                font-weight: bold;

                            }
                        }

                        .menu {
                            width: 100%;
                            height: 90%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            padding-left: 2%;
                            padding-right: 2%;
                            padding-top: 2%;

                            .categoryList {
                                height: 80%;
                                width: 20%;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 2px;
                                gap: 1vh;

                                .category {
                                    width: 100%;
                                    height: 20%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 2%;
                                    padding-top: 8%;
                                    padding-bottom: 8%;
                                    border-radius: 10%;


                                    img {
                                        object-fit: cover;
                                        width: 90%;
                                        height: 70%;
                                        border-radius: 10%;
                                    }

                                    .category-name {
                                        height: 20%;
                                        font-size: calc(2px + 0.490625vw);
                                        font-weight: 300;
                                    }
                                }


                            }

                            .itemlist {
                                height: 80%;
                                width: 78%;
                                display: flex;
                                flex-direction: column;
                                gap: 1%;

                                .item {
                                    width: 100%;
                                    height: 15%;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding: 2%;
                                    border-radius: 5px;

                                    img {
                                        object-fit: cover;
                                        width: 20%;
                                        height: 100%;
                                        border-radius: 10%;
                                    }

                                    .item-data {
                                        display: flex;
                                        flex-direction: column;
                                        height: 95%;
                                        width: 60%;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        text-align: left;
                                        padding-left: 1%;

                                        .item-name {
                                            font-size: calc(6px + 0.390625vw);
                                            padding: 0;
                                            margin: 0;
                                            width: 80%;

                                        }

                                        .item-desc {
                                            font-size: calc(2px + 0.390625vw);
                                            padding: 0;
                                            margin: 0;
                                            width: 80%;
                                        }
                                    }

                                    .add {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 20%;
                                        font-weight: bold;
                                        height: 90%;


                                        .extras {
                                            padding: 0.1rem;
                                            border-radius: 3px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: calc(2px + 0.390625vw);
                                            font-weight: 500;
                                            width: 100%;
                                        }

                                        .add-btn {
                                            padding: 0.1rem;
                                            border-radius: 3px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: calc(2px + 0.390625vw);
                                            font-weight: 500;
                                            width: 100%;

                                        }

                                        .price {
                                            font-size: 0.45em;
                                            font-weight: 500;
                                        }


                                    }
                                }
                            }
                        }

                    }
                }
            }

        }
    }

    .brandapp {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;
        flex-direction: row;
        height: 100%;
        position: relative;
        transition: 0.5s;



        .left {
            width: 50%;
            margin: auto;
        }

        .right {
            width: 50%;

            .wrap {
                flex-wrap: wrap;
            }

            .color-select {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .colorPicker {
                    flex-shrink: 0;
                    padding: 15px;
                    color: var(--main-text-color);
                    border-radius: 10px;
                    font-weight: 500;
                    border: 2px solid;
                    border-color: var(--border-color);
                    cursor: pointer;

                    .popover {

                        position: 'absolute';
                        z-index: 10;

                        .cover {
                            position: fixed;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                        }
                    }
                }
            }
        }

        .brandapp-container {
            background-color: #5e5e5e;
            border-radius: 30px;

            width: 60%;
            aspect-ratio: 9/18;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .screen {
                background-color: #000000;
                width: 99%;
                border-radius: 30px;
                aspect-ratio: 9/18;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .content {
                    height: 98%;
                    width: 95%;
                    border-radius: 20px;
                    margin: auto;
                    bottom: 1%;
                    position: absolute;
                    overflow: hidden;

                    .top-menu-bar {
                        // background-color: red;
                        width: 100%;
                        height: 10%;
                    }

                    .location-select {
                        // background-color: white;
                        width: 100%;
                        height: 8%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .menu {
                        // background-color: yellow;
                        width: 100%;
                        height: 72%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;

                        .category-list {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            justify-content: center;
                            align-items: center;

                            .category {
                                padding: 0.5em;
                            }

                        }

                        .order-btn {
                            position: absolute;
                            bottom: 2%;
                            padding: 4% 8% 4% 8%;
                            border-radius: 50px;
                        }
                    }

                    .bottom-bar {
                        // background-color: aqua;
                        width: 100%;
                        height: 10%;
                    }
                }

                .phone-bottom-bar {
                    position: absolute;
                    bottom: 3%;
                    width: 50%;
                    background-color: #000;
                    height: 0.6%;
                    border-radius: 90px;
                }

                .phone-top-bar {
                    position: absolute;
                    top: 0%;
                    width: 100%;
                }




            }
        }
    }


}