.top-bar {
  // overflow: hidden;
  margin: 0;
  background-color: var(--dark);
  position: fixed;
  border-bottom: 2px solid var(--main-bg-color);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.8em 3em;
  padding-bottom: 1em;
  width: 100%;
  max-width: 1920px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 55;

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .nav-elements {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .nav-element {
      margin: 0.5em 1em;
      padding: 6px;
      cursor: pointer;

      .dropdown {
        display: inline-block;
        position: relative;

        .dropdown-content {
          display: none;
          position: absolute;
          z-index: 9999;
          background-color: var(--dark);
          right: 10px;
          border-radius: 5px;
          border: 2px solid;
          border-color: var(--border-color);

          .dropdown-products-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-radius: 5px;
            width: 200px;
            flex-wrap: nowrap;

            &:hover {
              background-color: var(--highlighted-text-color);

              .icon {
                fill: #ffffff;
              }

              .name {
                color: #ffffff;
              }

              .icon-stroke {
                stroke: #ffffff;
              }
            }

            .dropdown-icons {
              width: 20%;

              .icon {
                width: 100%;
              }

              .icon-stroke {
                width: 100%;
              }
            }

            .name {
              width: 60%;
            }
          }

          a {
            display: inline-block;
          }
        }
      }

      .dropdown:hover .dropdown-content {
        display: flex;
        flex-direction: column;
      }

      a {
        color: var(--main-text-color);

        &:hover {
          color: var(--highlighted-text-color);
          transition: 0.8s;
        }
      }

      .selected {
        .teszt {
          // border-bottom: 3px solid;
          // border-color: var(--main-text-color);
          // padding-bottom: 3px;
          font-weight: bold;
          color: var(--highlighted-text-color);
        }
      }

      &.btn {
        a {
          color: var(--dark-blue);
        }

        background: #fff;
      }
    }
  }
}

.ntak-top-bar {
  // overflow: hidden;
  margin: 0;
  // background-color: var(--dark);
  position: fixed;
  //   border-bottom: 2px solid var(--main-bg-color);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.8em 3em;
  padding-bottom: 1em;
  width: 100%;
  max-width: 1920px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 55;

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  .ntak-logo {
    fill: white;
  }
  .nav-elements {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .nav-element {
      margin: 0.5em 1em;
      padding: 6px;
      cursor: pointer;

      .dropdown {
        display: inline-block;
        position: relative;
        color: white;

        .dropdown-content {
          display: none;
          position: absolute;
          z-index: 9999;
          background-color: var(--dark);
          right: 10px;
          border-radius: 5px;
          border: 2px solid;
          border-color: var(--border-color);

          .dropdown-products-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-radius: 5px;
            width: 200px;
            flex-wrap: nowrap;
            color: var(--main-text-color);

            &:hover {
              background-color: var(--highlighted-text-color);

              .icon {
                fill: #ffffff;
              }

              .name {
                color: #ffffff;
              }

              .icon-stroke {
                stroke: #ffffff;
              }
            }

            .dropdown-icons {
              width: 20%;

              .icon {
                width: 100%;
              }

              .icon-stroke {
                width: 100%;
              }
            }

            .name {
              width: 60%;
            }
          }

          a {
            display: inline-block;
          }
        }
      }

      .dropdown:hover .dropdown-content {
        display: flex;
        flex-direction: column;
      }

      a {
        color: white;

        &:hover {
          font-weight: bold;
          transition: 0.2s;
        }
        .active {
          font-weight: bold;
          color: white;
        }
      }

      .selected {
        .teszt {
          font-weight: bold;
          color: white;
        }
      }

      &.btn {
        a {
          color: var(--dark-blue);
        }

        background: #fff;
      }
    }
  }
}
