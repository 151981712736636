.footer {
  width: 100%;
  background-color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 10rem;

  .footer-card {
    background-color: var(--dark);

    position: relative;
    bottom: 5rem;
    border-radius: 20px;
    width: 80%;
    min-height: 440px;

    transition: 0.5s;
    box-shadow: 1px 10px 4px rgba(0, 0, 0, 0.05);

    &:hover {
      transform: translate(0, -20px);
      transition: 0.5s;

      .bg {
        opacity: 0;
        transition: 0.5s;
      }

      .filled-bg {
        opacity: 1;
        transition: 0.5s;
      }
    }

    .bg {
      flex-shrink: 0;
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      object-fit: cover;
      transition: 0.5s;
      padding-left: 2rem;
    }

    .filled-bg {
      transition: 0.5s;
      opacity: 0;
      flex-shrink: 0;
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      object-fit: cover;
      padding-left: 2rem;
    }

    .footer-text {
      padding: 20px;
      max-width: 25ch;
      padding-top: 15rem;
      font-size: 3rem;
      line-height: 1.15;
      font-weight: 600;
    }
  }

  .footer-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    max-width: 90.5rem;
    margin-left: auto;
    margin-right: auto;

    .menu-table {
      text-align: center;

      p {
        cursor: pointer;
      }
    }

    .footer-row {
      .email-subscription {
        background-color: var(--main-bg-color);
        border: none;
        height: 2.5rem;
        width: 100%;
        border-radius: 5px;
        color: white;
        padding-left: 1rem;
        font-weight: 500;
      }
    }
  }
}
