[data-theme="light"] {
    --main-bg-color: #FAFAFA;
    --main-text-color: #0e0e0e;
    --highlighted-text-color: #3F9AB7;

    --primary-btn-bg-color: #3F9AB7;
    --primary-btn-bg-color-hover: #31768C;
    --primary-btn-color: #FFFFFF;

    --nav-btn-bg-color: #3F9AB7;
    --nav-btn-color: #FFFFFF;
    --nav-btn-bg-color-hover: #252836;

    --thirty: #fff;

    --dark: #fff;

    --icon: #3F9AB7;

    --switch-theme-bg: #3F9AB7;
    --switch-theme-font: #FAFAFA;
    --switch-theme-icon: #FAFAFA;


    --form-bg: #F5F5FF;

    --border-color: #E4E4E4;
}

[data-theme="dark"] {
    --main-bg-color: #28282C;
    --main-text-color: #FFFFFF;
    --highlighted-text-color: #3F9AB7;

    --primary-btn-bg-color: #3F9AB7;
    --primary-btn-bg-color-hover: #31768C;

    --primary-btn-color: #fff;

    --nav-btn-bg-color: #383940;
    --nav-btn-color: #3F9AB7;
    --nav-btn-bg-color-hover: #28282C;

    --thirty: #1f2023;

    --dark: #28282C;
    --icon: #fff;

    --switch-theme-bg: #3F9AB7;
    --switch-theme-font: #FFFFFF;
    --switch-theme-icon: #FFFFFF;

    --form-bg: #1f2023;

    --border-color: #44434B;
}