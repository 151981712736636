.btn {
  background-color: var(--primary-btn-bg-color);
  color: var(--primary-btn-color);
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 3.5em;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  padding: 0 16px;
  font-family: 'Inter';
  position: relative;
  text-transform: uppercase;
  min-width: 180px;
  transition: all 0.7s;
  border: none;

  &:hover {
    background-color: var(--primary-btn-bg-color-hover);
    transform: scale(1.05);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  }

  a {
    color: white !important;
  }
}

.download-btn {
  background-color: var(--primary-btn-bg-color);
  color: var(--primary-btn-color);
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  font-family: 'Inter';
  position: relative;
  text-transform: uppercase;
  min-width: 180px;
  transition: all 0.7s;
  border: none;

  &:hover {
    background-color: var(--primary-btn-bg-color-hover);
    transform: scale(1.05);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  }

  a {
    color: white !important;
  }
}

.prod-btn {
  background-color: var(--primary-btn-bg-color);
  color: var(--primary-btn-color);
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5em;
  font-size: 0.8em;
  font-weight: bold;
  cursor: pointer;
  padding: 0 16px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.7s;
  border: none;
}

.outlined-btn {
  background-color: transparent;
  border: 4px solid;
  border-color: var(--primary-btn-bg-color);
  color: var(--primary-btn-bg-color);
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 3.5em;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  padding: 0 16px;
  font-family: 'Inter';
  position: relative;
  text-transform: uppercase;
  min-width: 180px;
  transition: all 0.7s;

  &:hover {
    background-color: var(--primary-btn-bg-color-hover);
    transform: scale(1.05);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.nav-btn {
  background-color: var(--nav-btn-bg-color);
  color: var(--nav-btn-color);
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 3.5em;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  padding: 0 16px;
  font-family: 'Inter';
  position: relative;
  text-transform: uppercase;
  min-width: 180px;
  transition: all 0.7s;

  &:hover {
    background-color: var(--nav-btn-bg-color-hover);
    transform: scale(1.05);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
  }
}
