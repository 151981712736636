.pos-features {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 50%;
    text-align: center;
  }
  .tabletPos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .left {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-end;
      gap: 20px;
    }
    .right {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;

      gap: 20px;
    }
    .center {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .element {
      width: 80%;
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;
      align-items: flex-start;
      // background-color: white;
      border-radius: 10px;
      padding: 10px 10px;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
      transition: 0.4s;

      p {
        text-align: left;
        font-weight: 800;
        font-size: 18px;
        width: 100%;
        color: var(--highlighted-text-color);
      }
      .desc {
        font-size: 14px;
      }

      &:hover {
        transform: scale(1.05);
        transition: 0.4s;
      }
    }
  }
}

.pos-devices {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .device-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    .device {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px 20px;
      width: 48%;
      border-radius: 10px;
      background-color: var(--main-bg-color);
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
      transition: 0.4s;

      .device-image {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }
      .text {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
      }

      &:hover {
        transform: scale(1.05);
        transition: 0.4s;
      }
    }
  }
}

.pos-theme {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .device-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    .device {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px 20px;
      width: 23%;
      border-radius: 10px;
      background-color: var(--main-bg-color);
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
      transition: 0.4s;
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.05);
        transition: 0.4s;
      }
    }
  }
}
