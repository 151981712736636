.ntak-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  .section-left {
    width: 48%;
    height: auto;
  }
  .section-right {
    width: 48%;
    height: auto;
  }
  .image-tab {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 30px;
  }

  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 80%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 2em;
      font-weight: 600;
      margin-top: 0.5rem;
    }

    p {
      font-size: 1em;
      font-weight: 300;
      margin-top: 0.5rem;
    }
  }
}

.ntak-categories {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--form-bg);
  border-radius: 30px;
  margin-top: 10px;

  .header {
    display: flex;
    flex-direction: column;
    max-width: 70%;

    a {
      color: var(--highlighted-text-color) !important;
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    .category {
      width: 20%;
      text-align: center;
    }
  }
}
.download {
  transition: 0.5s;
  position: relative;
  padding-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;

  .section-left {
    align-items: flex-end;
    width: 50%;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .text-section {
      transition: 0.5s;
      max-width: 30rem;
      text-align: left;
    }
  }
  .text-section {
    transition: 0.5s;
    // max-width: 30rem;
    text-align: left;
  }
  .section-right {
    width: 50%;
    margin-top: 2.5rem;
    margin-right: -0.5rem;
    display: flex;
    flex-wrap: wrap;

    .first-column {
      margin-top: -4rem;
    }

    .section-flexbox {
      transition: 0.5s;
      display: flex;
      align-items: flex-start;
      width: 50%;
      padding-right: 1rem;
      padding-left: 1rem;
      flex-direction: column;
      text-align: left;
    }
  }

  .section-header {
    transition: 0.5s;
    color: var(--highlighted-text-color);
    letter-spacing: -0.055em;
  }

  .section-label {
    transition: 0.5s;
    color: var(--main-text-color);
  }

  .section-text {
    transition: 0.5s;
    line-height: 112.5%;
    font-weight: 300;
    font-style: normal;
  }

  .section-image {
    transition: 0.5s;
    max-width: 90%;
  }
}
