.image-section {
    transition: 0.5s;
    position: relative;
    padding-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 80.5rem;
    margin-left: auto;
    margin-right: auto;
    height: auto;

    .section-left {
        align-items: flex-end;
        width: 50%;
        display: flex;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .text-section {
            transition: 0.5s;
            max-width: 30rem;
        }

    }

    .section-right {
        width: 50%;
        margin-top: 2.5rem;
        margin-right: -0.5rem;
        display: flex;
        flex-wrap: wrap;

        .first-column {
            margin-top: -4rem;
        }

        .section-flexbox {
            transition: 0.5s;
            display: flex;
            align-items: flex-start;
            width: 50%;
            padding-right: 1rem;
            padding-left: 1rem;
            flex-direction: column;
        }
    }

    .section-header {
        transition: 0.5s;
        color: var(--highlighted-text-color);
        letter-spacing: -0.055em;
    }

    .section-label {
        transition: 0.5s;
        color: var(--main-text-color)
    }

    .section-text {
        transition: 0.5s;
        line-height: 112.5%;
        font-weight: 300;
        font-style: normal;
    }

    .section-image {
        transition: 0.5s;
        max-width: 90%;
    }
}



.multiple-card-section {

    position: relative;

    .text-divider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a0a1a3;
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0px;

        .divider {
            height: 1px;
            -webkit-box-flex: 1;
            flex: 1;
            background-color: var(--border-color);
            transition: 0.5s;
        }

        .text-in-divider {
            margin-right: 0.75rem;
            margin-bottom: 0;
            margin-left: 0.75rem;
            transition: 0.5s;
        }
    }

    .section {
        padding-top: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;

        .section-left {
            align-items: flex-end;
            width: 50%;
            display: flex;
            padding: 1rem;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .text-section {
                max-width: 30rem;
            }

        }

        .section-right {
            width: 50%;
            margin-top: 2.5rem;
            margin-right: -0.5rem;
            display: flex;
            flex-wrap: wrap;



            .first-column {
                margin-top: -4rem;
            }

            .section-flexbox {
                display: flex;
                align-items: flex-start;
                width: 50%;
                padding-right: 1rem;
                padding-left: 1rem;
                flex-direction: column;
            }
        }
    }



    .section-header {
        transition: 0.5s;
        color: var(--highlighted-text-color);
        letter-spacing: -0.055em;
    }

    .section-label {
        transition: 0.5s;
        color: var(--main-text-color)
    }

    .section-text {
        transition: 0.5s;
        line-height: 112.5%;
        font-weight: 300;
        font-style: normal;
    }
}

.card-box {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background-color: var(--dark);
    border-radius: 10px;
    box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 5%);
    min-height: 10rem;
    margin-bottom: 2rem;
    padding: 1.75rem 1.5rem;
    transition: 1s;

    &:hover {
        box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 10%);
        transition: 0.5s;
        transform: translate(0, -8px);
    }

    .card-header {
        color: var(--main-text-color);
        margin: 0;
        transition: 0.5s;

    }

    .card-text {
        color: var(--main-text-color);
        font-size: 1rem;
        line-height: 1.1;
        font-weight: 300;
        margin: 0;
        margin-bottom: 16px;
        transition: 0.5s;
    }

    .card-details {
        color: var(--highlighted-text-color);

        &:hover {
            font-weight: 800;
            transition: 0.5s;
        }
    }

    .card-details {
        color: var(--highlighted-text-color);

        &:hover {
            font-weight: 800;
            transition: 0.5s;
        }
    }

    .pos-details {
        color: var(--highlighted-text-color);

        &:hover {
            font-weight: 800;
            transition: 0.5s;
        }
    }

    .brandapp-details {
        color: var(--highlighted-text-color);

        &:hover {
            font-weight: 800;
            transition: 0.5s;
        }
    }

    .kiosk-details {
        color: var(--highlighted-text-color);

        &:hover {
            font-weight: 800;
            transition: 0.5s;
        }
    }


}



.text-section {
    position: relative;

    .text-divider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a0a1a3;
        text-align: center;

        .divider {
            height: 1px;
            -webkit-box-flex: 1;
            flex: 1;
            background-color: var(--border-color);
            transition: 0.5s;
        }

        .text-in-divider {
            margin-right: 0.75rem;
            margin-bottom: 0;
            margin-left: 0.75rem;
        }
    }

    .section {
        padding-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .text-section {
            width: 70%;

            .section-header {
                color: var(--highlighted-text-color);
                transition: 0.5s;
                letter-spacing: -0.055em;
            }

            .section-label {
                color: var(--main-text-color);
                transition: 0.5s;
            }

            .section-text {
                line-height: 112.5%;
                font-weight: 300;
                font-style: normal;
            }

            .blog-text {
                line-height: 112.5%;
                font-weight: 500;
                font-style: normal;
            }
        }
    }
}

.cardList-section {
    position: relative;

    .pricing-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 2rem;
        margin: auto;
        width: 60%;

        .pricing-small-label {
            color: var(--highlighted-text-color);
            transition: 0.5s;
            margin: 0;
        }

        .pricing-label {
            color: var(--main-text-color);
            letter-spacing: -0.055em;
            margin: 0;
            transition: 0.5s;
            margin-top: 0.5rem;
        }

        .pricing-desc {
            color: var(--main-text-color);
            margin: 0;
            transition: 0.5s;
            margin-top: 0.5rem;
            font-weight: 300;
            max-width: 1000px;
            font-size: 16px;
        }
    }

    .pricing-list {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;

        .pricing-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            background-color: var(--dark);
            border-radius: 10px;
            box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 5%);
            min-height: 1rem;
            margin-bottom: 2rem;
            margin-right: 2rem;
            padding: 1.75rem 1.5rem;
            transition: 0.5s;
            cursor: default;

            &:hover {
                box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 10%);
                transition: 0.5s;
                transform: translate(0, -8px);
            }

            .package-name {
                margin: 0;
                transition: 0.5s;
            }

            .package-description {
                margin: 0;
                transition: 0.5s;
                font-weight: 200;
            }

            .package-price {
                margin: 0;
                margin-top: 1rem;
                color: var(--highlighted-text-color);
                transition: 0.5s;
            }
        }
    }

    .pricing-list {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;

        .pricing-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            background-color: var(--dark);
            border-radius: 10px;
            box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 5%);
            min-height: 10rem;
            margin-bottom: 2rem;
            margin-right: 2rem;
            padding: 1.75rem 1.5rem;
            transition: 0.5s;
            width: 25%;
            text-align: center;

            &:hover {
                box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 10%);
                transition: 0.5s;
                transform: translate(0, -8px);
                color: var(--highlighted-text-color);
            }

            .package-name {
                transition: 0.5s;
                margin: 0;
            }

            .package-description {
                margin: 0;
                font-weight: 200;
                transition: 0.5s;

            }

            .package-price {
                margin: 0;
                margin-top: 1rem;
                color: var(--highlighted-text-color);
                transition: 0.5s;
            }
        }
    }
}


.pricing-section {
    position: relative;

    .pricing-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 2rem;

        .pricing-small-label {
            color: var(--highlighted-text-color);
            margin: 0;
        }

        .pricing-label {
            color: var(--main-text-color);
            letter-spacing: -0.055em;
            margin: 0;
            margin-top: 0.5rem;
        }

        .pricing-desc {
            color: var(--main-text-color);
            margin: 0;
            margin-top: 0.5rem;
            font-weight: 300;
            max-width: 1000px;
            font-size: 16px;
        }
    }

    .pricing-list {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;

        .pricing-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            background-color: var(--dark);
            border-radius: 10px;
            box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 5%);
            min-height: 10rem;
            margin-bottom: 2rem;
            margin-right: 2rem;
            padding: 1.75rem 1.5rem;
            transition: 0.5s;
            position: relative;

            &:hover {
                box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 10%);
                transition: 0.5s;
                transform: translate(0, -8px);
            }

            .package-name {
                margin: 0;
            }

            .package-description {
                margin: 0;
                font-weight: 200;
                text-align: left;
                padding-bottom: 20px;
            }

            .package-price {
                margin: 0;
                margin-top: 1rem;
                bottom: 20px;
                position: absolute;
                color: var(--highlighted-text-color);
            }
        }
    }

    .pricing-list {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        max-width: 80.5rem;
        margin-left: auto;
        margin-right: auto;

        .pricing-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            background-color: var(--dark);
            border-radius: 10px;
            box-shadow: 0px 8px 10px 0 rgb(0 0 0 / 5%);
            min-height: 10rem;
            margin-bottom: 2rem;
            margin-right: 2rem;
            padding: 1.75rem 1.5rem;
            transition: 0.5s;
            width: 25%;
            text-align: center;

            &:hover {
                box-shadow: 0 16px 2rem 0 rgb(18 20 25 / 16%);
                transition: 0.5s;
                transform: translate(0, -8px);
            }

            .package-name {
                margin: 0;
            }

            .package-description {
                margin: 0;
                font-weight: 200;
            }

            .package-price {
                margin: 0;
                margin-top: 1rem;
                color: var(--highlighted-text-color);
            }
        }
    }

    .extras {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 80%;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        .extra-card {
            background-color: var(--dark);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px;
            padding-right: 40px;
            border-radius: 10px;
            gap: 10px;
            width: 300px;
            height: 100px;
            transition: 0.5s;
            border: 1px solid;
            border-color: var(--dark);
            box-shadow: 0px 4px 8px rgba(59, 59, 59, 0.25);

            &:hover {
                box-shadow: 0 16px 2rem 0 rgb(18 20 25 / 16%);
                transition: 0.5s;
                transform: translate(0, -18px);
                border: 1px solid;
                border-color: var(--highlighted-text-color);
            }

            .icon {
                width: 40px;
                height: 40px;
                fill: var(--main-text-color);
            }

            .icon-stroke {
                width: 100%;
                stroke-width: 1px;
                stroke: var(--main-text-color);
            }

            .name-and-price {
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: left;

                .extra-name {
                    font-weight: 400;
                    font-size: 16px;
                    margin: 0;
                }

                .extra-price {
                    color: var(--highlighted-text-color);
                    font-size: 14px;
                    margin: 0;

                }
            }

        }
    }

}


.pos-demo-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--thirty);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(43, 43, 43, 0.25);
    min-height: 900px;
    max-height: 1080px;
    position: relative;

    h2,
    p {
        margin: 0;
        padding: 0;
    }

    .pos-demo {
        width: 95%;
        min-height: 800px;
        max-height: 980px;
        border: none;
        border-radius: 10px;
    }
}

.blog-section {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;

    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .section-header {
            color: var(--highlighted-text-color);
            transition: 0.5s;
            letter-spacing: -0.055em;
            text-align: center;
        }

        .section-label {
            color: var(--main-text-color);
            transition: 0.5s;
            text-align: center;
        }

        .section-text {
            line-height: 112.5%;
            font-weight: 300;
            font-style: normal;
        }

        .blog-two-side {
            display: flex;
            flex-direction: row;
            justify-content: space-between;


            .left {
                width: 50%;

                .blog-text {
                    line-height: 112.5%;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 1.1em;
                    text-align: left;
                    width: 80%;
                }

                .task {
                    font-weight: 600;
                }
            }

            .right {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 90%;
                    border-radius: 10px;
                }
            }
        }






    }

}