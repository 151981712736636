.form-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--thirty);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(43, 43, 43, 0.25);

    .formInput {
        width: 95%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .tax-error {
            color: red;
            text-align: left;
            font-weight: 200;
            margin: 0;
            padding: 0;
            font-size: 0.8rem;
        }

        input {
            background-color: var(--form-bg);
            transition: 0.5s;
            border: none;
            padding: 15px;
            border-radius: 5px;
            color: var(--main-text-color);
            width: 40%;

            &:focus {
                border: none;
            }
        }

        .message {
            transition: 0.5s;
            background-color: var(--main-bg-color);
            border: none;
            padding: 15px;
            border-radius: 5px;
            color: var(--main-text-color);
            width: 81%;
            font-family: 'Inter', sans-serif;
            resize: none;
        }

        .input-mask {
            opacity: 0.5;
        }
    }



    p {
        margin: 0;
        width: 80%;
        text-align: center;
    }

    h1 {
        margin: 0;
        text-align: center;
    }
}