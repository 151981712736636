.app-header {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  position: relative;
  width: 100%;
  height: 960px;

  .app-header-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    z-index: 0;
  }

  .welcome-root {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: auto;
    position: relative;
    z-index: 4;
    display: flex;

    .logo-on-mobile {
      display: none;
    }

    h1 {
      font-size: 46px;
    }

    .welcome-text {
      max-width: 600px;
    }

    .col {
      padding: 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .row {
        margin: 2em 0;
        display: flex;
        gap: 50px;

        .contacts-link {
          display: flex;
          align-items: center;
          border-bottom: 3px solid #fff;
          padding: 0 12px;
          cursor: pointer;

          .text {
            a {
              color: #fff;
            }
          }

          .icon {
            height: 21px;

            img {
              height: 100%;
              margin-right: 12px;
            }
          }
        }
      }
    }

    .col-left {
      width: 50%;
      align-items: flex-end;
      position: relative;

      .header-image {
        width: 100%;
        left: -20px;
        position: absolute;
      }

      .box-row-left {
        align-self: start;
      }
    }

    .col-right {
      width: 50%;
    }
  }
}

.new-app-header {
  background-color: var(--dark);
  color: var(--main-text-color);
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 960px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s;
  margin-bottom: 20px;

  .design-object {
    position: absolute;
    top: -20%;
    left: -6%;
    transition: 0.5s;
  }

  .app-header-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
    transition: 0.5s;
  }

  .content {
    position: relative;
    margin-left: 5%;
    max-width: 40%;
    transition: 0.5s;

    h1 {
      font-weight: 800;
    }

    .first-line {
      display: flex;
      flex-direction: row;
      gap: 5px;
      flex-wrap: wrap;
      font-weight: 300;
      font-size: 2rem;

      .hightlighted {
        font-weight: 800;
        color: var(--highlighted-text-color);
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .welcome-text {
      max-width: 70%;
      padding-bottom: 30px;
    }
  }
}
.ntak-new-app-header {
  color: white;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 960px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s;
  margin-bottom: 20px;

  .app-header-bg {
    // background: linear-gradient(104.56deg, #3f9ab7 -2.64%, #1c7d6c 99.06%) !important;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
    transition: 0.5s;
  }

  .content {
    position: relative;
    margin-left: 5%;
    max-width: 40%;
    transition: 0.5s;

    h1 {
      font-weight: 800;
    }

    .first-line {
      display: flex;
      flex-direction: row;
      gap: 5px;
      flex-wrap: wrap;
      font-weight: 300;
      font-size: 2rem;

      .hightlighted {
        font-weight: 800;
        color: var(--highlighted-text-color);
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .welcome-text {
      max-width: 70%;
      padding-bottom: 30px;
    }
  }
}
