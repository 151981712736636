@media (max-width: 1280px) {
  #root {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  .new-app-header {
    .app-header-bg {
      display: none;
    }

    .content {
      max-width: 100% !important;
      margin: 20px !important;

      .row {
        flex-direction: column !important;
      }
    }
  }
  .ntak-new-app-header {
    .app-header-bg {
      display: none;
    }
    background: linear-gradient(104.56deg, #3f9ab7 -2.64%, #1c7d6c 99.06%) !important;

    .content {
      max-width: 100% !important;
      margin: 20px !important;

      .row {
        flex-direction: column !important;
      }
    }
  }

  .top-bar {
    padding: 1em 12px !important;
    flex-direction: column;

    .content {
      width: 100% !important;
    }
    .nav-btn {
      display: none;
    }
    .img-container {
      display: none;
    }
  }
  .ntak-top-bar {
    padding: 1em 12px !important;
    flex-direction: column;
    background: linear-gradient(104.56deg, #3f9ab7 -2.64%, #1c7d6c 99.06%);

    .content {
      width: 100% !important;
    }
    .nav-btn {
      display: none;
    }
    .img-container {
      display: none;
    }
  }
}

@media (max-width: 1180px) {
  .editor {
    flex-direction: column !important;

    .kiosk {
      flex-direction: column !important;

      width: 100% !important;
      transition: 0.5s;

      .left,
      .right {
        width: 100% !important;
      }

      .kiosk-container {
        width: 50% !important;

        .category-name {
          font-size: calc(6px + 0.490625vw) !important;
        }
      }
    }
  }
}

@media screen and (orientation: portrait) {
  .new-app-header {
    .design-object {
      display: block;
    }
  }

  .design-object {
    display: none;
  }

  .design-object-left {
    display: none;
  }

  .cookie {
    width: 100% !important;
  }

  .App {
    overflow: hidden;
  }

  .top-bar {
    .img-container {
      display: none;
    }

    .nav-elements {
      .dropdown-content {
        .dropdown-products-container {
          flex-direction: row !important;
        }
      }

      .dropdown:hover .dropdown-content {
        flex-direction: column !important;
      }

      .nav-btn {
        display: none;
      }
    }
  }

  .btn {
    height: 2.5em;
  }

  // Header and Nav
  .top-bar {
    padding: 1em 12px !important;
    flex-direction: column;

    .content {
      width: 100% !important;
    }

    .img-container {
      display: none;
    }

    .nav-elements {
      justify-content: center;

      .nav-element {
        margin: 3px;
        font-size: 14px;
      }

      .nav-btn {
        margin-top: 20px;
      }
    }
  }
  .ntak-top-bar {
    padding: 1em 12px !important;
    flex-direction: column;

    .content {
      width: 100% !important;
    }

    .img-container {
      display: none;
    }

    .nav-elements {
      justify-content: center;

      .nav-element {
        margin: 3px;
        font-size: 14px;
      }

      .nav-btn {
        margin-top: 20px;
      }
    }
  }

  .app-header {
    height: auto !important;

    .welcome-root {
      margin-top: 100px !important;
      height: auto !important;

      .logo-on-mobile {
        display: block !important;
      }

      .header-image {
        display: none !important;
      }

      .col-left {
        display: none !important;
      }

      .col-right {
        padding: 69px 12px 36px 12px !important;
        min-width: unset !important;
        width: 100% !important;
      }
    }
  }

  //  Multiple card section
  .multiple-card-section {
    .section-left,
    .section-right {
      width: 100% !important;
    }

    .section {
      flex-direction: column !important;

      .section-right {
        flex-direction: column;

        .first-column,
        .second-column {
          width: 100% !important;
        }
      }
    }
  }

  //Image section

  .image-section {
    flex-direction: column !important;

    .section-right,
    .section-left {
      width: 90% !important;
    }
  }

  .image-on-right-text {
    order: 2;
  }

  .image-on-right-image {
    order: 1;
  }

  // CardList section
  .cardList-section {
    .pricing-list {
      flex-direction: column !important;

      .pricing-card {
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }

  // pricing
  .pricing-section {
    margin-left: 2rem;
    margin-right: 2rem;

    .pricing-list {
      flex-direction: column !important;
    }

    .pricing-card {
      margin-left: auto !important;
      margin-right: auto !important;
      width: 100% !important;
    }
  }

  // Footer
  .footer {
    .footer-card {
      min-height: 220px !important;

      .bg {
        display: none;
      }

      .footer-text {
        padding-top: 2rem !important;
        font-size: 2rem !important;
      }
    }

    .footer-table {
      flex-direction: column !important;
      gap: 1rem !important;
      padding-bottom: 2rem;
    }
  }

  // CONTACT
  .form-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--thirty);
    padding: 20px;
    border-radius: 20px;

    .formInput {
      flex-wrap: nowrap !important;
      flex-direction: column !important;

      input {
        width: 100% !important;
      }

      .message {
        width: 100% !important;
      }
    }
  }

  .editor {
    flex-direction: column !important;

    .kiosk {
      flex-direction: column !important;

      width: 100% !important;

      .left,
      .right {
        width: 100% !important;
      }

      .left {
        order: 2 !important;
      }

      .right {
        order: 1 !important;
        padding-bottom: 20px !important;
      }

      .kiosk-container {
        width: 100% !important;

        .category-name {
          font-size: calc(6px + 0.490625vw) !important;
        }
      }
    }
  }

  .pricing-calculator {
    .calculator {
      flex-direction: column !important;
    }

    .summary {
      width: 100% !important;
      align-items: center !important;
      flex-direction: row !important;
      gap: 20px !important;
    }

    .calculator-items {
      width: 100% !important;
    }
  }

  .ntak-options {
    flex-direction: column !important;
    gap: 10px !important;
    .image-tab {
      width: 100% !important;
    }
  }

  .ntak-categories {
    .content {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      .category {
        width: 100% !important;
      }
    }
  }

  .download {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 20px !important;

    .section-left {
      width: 100% !important;

      flex-direction: column !important;
      gap: 10px;

      div {
        width: 100% !important;
      }
    }
    .section-right {
      width: 100% !important;
    }
  }

  .pos-features {
    .tabletPos {
      flex-direction: column !important;

      .left {
        width: 80% !important;
        align-items: center !important;
        order: 1;
        margin-bottom: 10px;
      }

      .right {
        width: 80% !important;
        align-items: center !important;
        order: 2;
      }
      .center {
        order: 3;
        width: 80% !important;
        align-items: center !important;

        img {
          width: 100%;
        }
      }
    }
  }

  .pos-devices {
    .device-container {
      .device {
        width: 90% !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin-left: auto !important;
        margin-right: auto !important;

        .device-image {
          width: 100% !important;
        }
        .text {
          text-align: center !important;
        }
      }
    }
  }

  .pos-theme {
    .device-container {
      .device {
        width: 90% !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        .text {
          text-align: center !important;
        }
      }
    }
  }
}
