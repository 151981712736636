$toggle-indicator-size: 18px;
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.5;

.pricing-calculator {
    background-color: var(--dark);
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 40px;

    border-radius: 20px;

    padding-bottom: 30px;

    .calculator {
        width: 100%;
        display: flex;
        flex-direction: row;

        .calculator-items {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 70%;
            justify-content: center;
            align-items: flex-start;
            user-select: none;




            .toggle {
                background-color: var(--main-bg-color);
                align-items: center;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;

                &:checked {
                    background-color: red;
                }

                .toggle-track {
                    flex-shrink: 0;
                    margin-left: 20px;
                    background: #FFF;
                    border-radius: 100%;
                    display: flex;
                    height: 24px;
                    width: 24px;
                    margin-right: 12px;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid;
                    border-color: var(--border-color);

                    .toggle-indicator {
                        align-items: center;
                        background: var(--dark);
                        border-radius: 100px;
                        bottom: 2px;
                        display: flex;
                        height: 18px;
                        justify-content: center;
                        left: 2px;

                        transition: 0.3s;
                        width: 18px;


                    }
                }

                .toggle__input {
                    clip: rect(0 0 0 0);
                    clip-path: inset(50%);
                    height: 1px;
                    overflow: hidden;
                    position: absolute;
                    white-space: nowrap;
                    width: 1px;

                    &:not([disabled]):active+.toggle-track,
                    &:not([disabled]):focus+.toggle-track {
                        border: 1px solid transparent;
                        box-shadow: red;
                    }

                    &:disabled+.toggle-track {
                        cursor: not-allowed;
                        opacity: 0.7;
                    }

                    &:checked+.toggle-track .toggle-indicator {
                        background-color: var(--highlighted-text-color);


                        input {
                            background-color: red;
                        }


                    }

                }

                .extra-card {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    border-radius: 10px;
                    gap: 10px;
                    transition: 0.5s;
                    width: 100%;
                    height: 90px;

                    .icon {
                        width: 40px;
                        height: 40px;
                        fill: var(--main-text-color);
                    }

                    .icon-stroke {
                        width: 30%;
                        stroke-width: 1px;
                        stroke: var(--main-text-color);
                    }

                    .name-and-price {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        text-align: left;

                        .extra-name {
                            font-weight: 400;
                            font-size: 14px;
                            margin: 0;
                        }

                        .extra-price {
                            color: var(--highlighted-text-color);
                            font-size: 12px;
                            margin: 0;

                        }
                    }
                }
            }


        }

        .summary {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 30%;
            margin: auto;
            align-items: flex-start;
            justify-content: center;
            height: 100%;


            .tab-selector {
                display: flex;
                flex-direction: row;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
                border: 2px solid;
                border-color: var(--border-color);

                .tab {
                    padding: 20px;
                    background-color: var(--main-bg-color);
                    cursor: pointer;

                }

                .active {
                    background-color: var(--highlighted-text-color);
                    color: #FFFFFF;
                    font-weight: bold;
                }
            }

        }
    }


}